
    .student-container {
        background: #F7F9FB;
        width: 100vw;
        height: 100vh;
        display: flex;
        .left-wrapper {
            width: 280px;
            background: #2C405F;
            height: 100%;
            display: flex;
            flex-direction: column;
            font-weight: 500;
            .left-header {
                text-align: center;
                .logo {
                    margin-top: 36px;
                    display: inline-block;
                    width: 60px;
                    height: 60px;
                    background: #FD4446;
                    border-radius: 10px;
                    text-align: center;
                    line-height: 60px;
                    .iconfont {
                        display: block;
                        font-size: 33px;
                        color: #fff;
                    }
                }
                .theme {
                    margin-top: 22px;
                    font-size: 28px;
                    color: #fff;
                    line-height: 1;
                }
            }
            .left-menu-wrapper {
                margin-top: 50px;
                height: 1%;
                flex: 1;
                /deep/ .el-scrollbar__wrap {
                    overflow-x: hidden;
                }
                .left-menu {
                    height: 100%;
                    .nav-item {
                        cursor: pointer;
                        color: #fff;
                        display: flex;
                        align-items: center;
                        padding: 0 20px 0 40px;
                        height: 60px;
                        position: relative;
                        margin-bottom: 20px;
                        /*pointer-events: none;*/
                        /*cursor: not-allowed;*/
                        &:before {
                            content: '';
                            position: absolute;
                            left: 48px;
                            bottom: 38px;
                            width: 1px;
                            background: #707C92;
                            height: 64px;
                        }
                        &:first-child {
                            &:before {
                                content: none;
                            }
                        }
                        .status {
                            width: 15px;
                            height: 15px;
                            border: 1px solid #707C92;
                            border-radius: 50%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            .iconfont {
                                font-size: 12px;
                                line-height: 13px;
                                display: none;
                            }
                        }
                        .nav-icon {
                            margin-left: 17px;
                            font-size: 24px;
                            margin-right: 20px;
                            font-weight: normal;
                            transition: all .3s;
                        }
                        .text {
                            font-size: 20px;
                            transition: all .3s;
                        }
                        &.is-finish {
                            cursor: pointer;
                            /*pointer-events: auto;*/
                            &:before {
                                background: #FD4446;
                            }
                            .status {
                                border-color: #FD4446;
                                .iconfont {
                                    display: block;
                                    color: #FD4446;
                                }
                            }
                            &:hover {
                                .nav-icon {
                                    color: #FD4446;
                                }
                                .text {
                                    color: #FD4446;
                                }
                            }
                        }
                        &.is-active {
                            cursor: pointer;
                            /*pointer-events: auto;*/
                            &:before {
                                background: #FD4446;
                            }
                            .status {
                                border-color: #FD4446;
                                background: #FD4446;
                            }
                            &:hover {
                                .nav-icon {
                                    color: #FD4446;
                                }
                                .text {
                                    color: #FD4446;
                                }
                            }
                        }
                    }
                }
            }
        }
        .right-wrapper {
            width: 1%;
            flex: 1;
            display: flex;
            flex-direction: column;
            .right-header {
                height: 80px;
                background: #fff;
                border-bottom: 1px solid #D1D2DB;
                box-sizing: border-box;
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
                .countdown {
                    font-size: 18px;
                    color: #FD4446;
                    font-weight: 500;
                    .time {
                        margin-left: 10px;
                    }
                }
                .user-wrapper {
                    position: absolute;
                    right: 30px;
                    display: flex;
                    .user-info {
                        display: flex;
                        align-items: center;
                        .user-avatar {
                            width: 32px;
                            height: 32px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            overflow: hidden;
                            border-radius: 50%;
                            border: 1px solid #eee;
                            box-sizing: border-box;
                            img {
                                max-width: 100%;
                                max-height: 100%;
                            }
                        }
                        .user-name {
                            margin-left: 6px;
                        }
                    }
                    .exit-btn {
                        margin-left: 24px;
                        display: flex;
                        align-items: center;
                        cursor: pointer;
                        transition: all .3s;
                        .iconfont {
                            font-size: 20px;
                        }
                        span {
                            margin-left: 6px;
                        }
                        &:hover {
                            color: #1122D8;
                        }
                    }
                }
            }
            /deep/ .right-content {
                height: 1%;
                flex: 1;
                .el-scrollbar__wrap {
                    overflow-x: hidden;
                    .el-scrollbar__view {
                        min-width: 900px;
                        /*height: 100%;*/
                    }
                    .right-wrapper-content {
                        padding: 20px 30px 20px 30px;
                        box-sizing: border-box;
                    }
                }
            }
        }
    }
    /deep/ p {
        margin: 0;
    }
