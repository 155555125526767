
    .dataCenter {
        height: 100%;
        display: flex;
        flex-direction: column;

        .education-content {
            flex: 1;
            height: 1%;
        }

        .router-content {
            flex: 1;
            height: 1%;
            background: #fff;
        }
    }
