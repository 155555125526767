
.register-layout {
  height: 100vh;
  display: flex;
  flex-direction: column;
}
.content-wrapper {
  height: 1%;
  flex: 1;
  background: #f7f9fb;
  padding: 20px 30px 20px 40px;
}
.content-box {
  height: 100%;
  :deep(& > .el-scrollbar__wrap) {
    overflow-x: hidden;
    .el-scrollbar__view {
      //height: 100%;
    }
  }
}
.content {
  background: #fff;
  min-width: 1000px;
}
.header-box {
  display: flex;
  height: 80px;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px;
  background: #fff;
  border-bottom: 1px solid #D1D2DB;
  .title {
    width: 268px;
    font-size: 18px;
    font-weight: 500;
  }
}
.user-wrapper {
  display: flex;
  .user-info {
    display: flex;
    align-items: center;
    .user-avatar {
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      border-radius: 50%;
      border: 1px solid #eee;
      box-sizing: border-box;
      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
    .user-name {
      margin-left: 6px;
    }
  }
  .exit-btn {
    margin-left: 24px;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: all .3s;
    .iconfont {
      font-size: 20px;
    }
    span {
      margin-left: 6px;
    }
    &:hover {
      color: #1122D8;
    }
  }
}
