
    .education-content {
        height: 100%;
        display: flex;
        flex-direction: column;
        .container {
            flex: 1;
            background: #fff;
            overflow: hidden;
        }
    }
